import {getLocalStorageService, setLocalStorageService} from "../services/storage.service";

function GetLang() {
	var urlArr = window.location.pathname.split("/").filter(n => n);
    //Приоритет - язык указанный в url
	var urlLang = urlArr[0];
	if (urlLang && (urlLang == "ru" || urlLang == "en"))
		setLocalStorageService("language", urlLang);
	else
		urlLang = getLocalStorageService("language") || "en";
	
	return urlLang;
}

export {GetLang}