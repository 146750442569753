/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace } from 'vuex-class'
import { Shop } from '@/store/shop/types'
import * as lang from "@/store/lang";

const page = namespace('shop');

export default class ModelChoicePage extends Vue {
    @page.State('shop') shop: Shop | undefined;
    @page.Action('getShopModels') getShopModels: any;
    @page.Getter('shopModels') shopModels: any;

    lang: any = lang.GetLang();

    avatar(base64 : any) {
        if (base64)
            return "background-image: url('" + base64 + "')";
    }

	mounted() {
        var _this = this;
		this.getShopModels();
	}
}
